import { Flex } from "@chakra-ui/react";

import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  NextPage,
} from "next";
import Head from "next/head";

import { prisma } from "lib/prisma";

import { LandingPageWithCampaignInformation } from "@lib/prismaTypes";
import {
  LandingPageContainer,
  FontLoader,
  LandingPageConfiguration,
  TrackingLoader,
} from "@textnology-inc/landing-page-package";
import "components/landingPages/fonts.module.css";
import { getTopLevelDomain } from "@utils/getTopLevelDomain";
import {
  blockedRefererWebsitesSet,
  wordsInDomainToRestrict,
} from "@utils/data/blockedRefererWebsites";
import { RedirectCouponSiteProps } from "middleware/withRedirectCouponSite";
import { withRedirectCouponSite } from "middleware/withRedirectCouponSite";
import { _pageComponentDictionary } from "pageComponents/_pageComponentDictionary";
import { ReferralEnums } from "enums/referral.enum";
import { urlSlugToPageComponentKey } from "../utils/urlSlugToPageComponentKey";
import { LandingPageTypeEnums } from "@prisma/client";
import { GetCampaignByPhoneNumberResponse } from "./api/campaigns/phone-number";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export type GetLandingPageInformation = {
  data: LandingPageWithCampaignInformation;
  dictionaryKey?: string;
} & RedirectCouponSiteProps;

const TemplatedPage: NextPage<GetLandingPageInformation> = ({
  data,
  referrerUrl,
  dictionaryKey,
}) => {
  const getCampaignRequest = async (campaignPhoneNumber: string) => {
    try {
      const res = await axios.get<GetCampaignByPhoneNumberResponse>(
        `/api/campaigns/phone-number`,
        {
          params: {
            campaignPhoneNumber,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const { data: campaignData, error } = useQuery(
    ["campaignData", data?.campaign?.phoneNumber],
    () => getCampaignRequest(data?.campaign?.phoneNumber || ""),
    {
      enabled: !!data?.campaign?.phoneNumber,
    }
  );

  // error log for useQuery
  if (error) {
    console.log(error);
  }

  if (dictionaryKey) {
    const PageComponent = _pageComponentDictionary[dictionaryKey];

    return <PageComponent />;
  }

  const configuration =
    data.configuration as unknown as LandingPageConfiguration;

  const fonts = configuration.fonts ?? [];

  const pageTitleName = configuration.metadata?.title ?? "Offer";
  const pageTitle = `${pageTitleName} | Aisle`;
  const hasApprovedPartnerTerms =
    campaignData?.campaign.brand.hasApprovedPartnerTerms ?? false;

  return (
    <Flex direction="column" m="0">
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <FontLoader fonts={fonts} />
      <TrackingLoader tracking={configuration.tracking} />
      <LandingPageContainer
        isPartner={hasApprovedPartnerTerms}
        data={configuration}
        campaignPhoneNumber={data.campaign.phoneNumber}
        referrerUrl={referrerUrl}
      />
    </Flex>
  );
};

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const { req, res: response, params } = context;

  // Check to see if we have a dictionary component for it.
  // vercel has a 1024 path limit that we exceeded on 10/25/23,
  // all new pages must be created in this way to eliminate creation of additional paths

  // If it came with a -referral appended then we need to trim it so we find the key in the dictionary
  const slug =
    typeof params?._slug === "string"
      ? params._slug.replace(ReferralEnums.REDIRECT, "")
      : undefined;

  const pageComponentKey = slug ? urlSlugToPageComponentKey[slug] : undefined;

  if (pageComponentKey) {
    const { props: couponRedirectProps, ...restCouponRedirect } =
      await withRedirectCouponSite(context);

    return {
      props: {
        data: {},
        dictionaryKey: pageComponentKey,
        ...couponRedirectProps,
      },
      ...restCouponRedirect,
    };
  }

  if (req.headers.referer) {
    const refererHostName = getTopLevelDomain(req.headers.referer);
    const isDomainInBlockList = blockedRefererWebsitesSet.has(refererHostName);
    const hasDisallowedWordInDomain = !!wordsInDomainToRestrict.find((word) =>
      refererHostName.includes(word)
    );

    if (isDomainInBlockList || hasDisallowedWordInDomain) {
      console.dir({
        note: "User was permanently redirected",
        referer: req.headers.referer,
        normalizedReferer: refererHostName,
        reason: {
          isDomainInBlockList,
          hasDisallowedWordInDomain,
        },
      });
      return {
        redirect: {
          destination: "/offer-expired",
          permanent: false,
        },
      };
    }
  }

  response.setHeader(
    "Cache-Control",
    "public, s-maxage=10, stale-while-revalidate=59"
  );

  if (!slug || typeof slug !== "string") {
    return {
      notFound: true,
    };
  }

  const landingPageData = await prisma.landingPage.findFirst({
    where: {
      slug,
      isActive: true,
    },
    include: {
      campaign: {
        select: {
          isPaused: true,
          isActive: true,
          phoneNumber: true,
        },
      },
    },
  });

  if (!landingPageData) {
    return {
      notFound: true,
    };
  }

  // if the user is trying to access a new landing page,
  // then redirect them to the new subdomain (try.gotoaisle)
  if (landingPageData?.type === LandingPageTypeEnums.CAMPAIGN_LANDING_PAGE) {
    const builderLandingPageUrl = new URL(slug, "https://try.gotoaisle.com");
    builderLandingPageUrl.search = new URLSearchParams(
      context.query as Record<string, string>
    ).toString();

    return {
      redirect: {
        destination: builderLandingPageUrl.href,
        permanent: false,
      },
    };
  }

  if (landingPageData.campaign.isPaused || !landingPageData.campaign.isActive) {
    return {
      redirect: {
        destination: `${process.env.DOMAIN_PATH}/inactive-campaign`,
        permanent: false,
      },
    };
  }

  return {
    props: {
      data: landingPageData,
      referrerUrl: req.headers.referer,
    },
  };
};

export default TemplatedPage;
